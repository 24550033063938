<template>
  <AdminView>
    <router-link class="back-link" :to="{ name: 'ExercisesList' }"
      >&lt; Retour</router-link
    >

    <div v-if="mode === 'edit'">
      <h1 class="title-1">Modifier l'exercice</h1>
      <ExerciceForm v-if="!isExerciseEmpty" :exercise="exercise" mode="edit" />
    </div>

    <div v-if="mode === 'add'">
      <h1 class="title-1">Ajouter un exercice</h1>
      <ExerciceForm mode="add" />
    </div>
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import AdminView from "@/components/AdminView"
import ExerciceForm from "@/components/Forms/ExerciseForm"

export default {
  name: "Exercise",
  props: {
    mode: {
      type: String,
      default: "edit"
    }
  },
  components: {
    AdminView,
    ExerciceForm
  },
  async created() {
    await this.$store.dispatch("admin/getExercises")
  },
  computed: {
    ...mapGetters({
      getExercise: "admin/getExercise"
    }),
    exercise() {
      const exerciseId = this.$route.params.id
      return this.getExercise(exerciseId)
    },
    isExerciseEmpty() {
      return _.isEmpty(this.exercise)
    }
  }
}
</script>

<style lang="scss" scoped></style>
