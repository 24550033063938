<template>
  <div class="form">
    <div class="row">
      <div class="column">
        <div class="row">
          <Input
            type="text"
            title="Code"
            name="exercice-code"
            v-model="editableExercise.code"
          />
        </div>
        <div class="row">
          <Input
            type="text"
            title="Titre *"
            name="exercice-name"
            v-model="editableExercise.name"
          />
        </div>
        <div class="row">
          <Textarea
            title="Consigne écrite"
            name="exercice-description"
            v-model="editableExercise.description"
          />
        </div>
      </div>
      <div class="column">
        <InputFile
          title="Image *"
          type="image"
          :typeFile="'thumbnail'"
          @changeUrl="changeUrl"
          :preview="editableExercise.thumbnailUrl"
          v-model="editableExercise.thumbnailUrl"
          :onUploadStarts="() => (isUploadingThumbnail = true)"
          :onUploadEnds="() => (isUploadingThumbnail = false)"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <InputFile
          title="Vidéo d'introduction *"
          type="video"
          :typeFile="'introVideo'"
          :preview="editableExercise.introVideoUrl"
          v-model="editableExercise.introVideoUrl"
          @changeUrl="changeUrl"
          :onUploadStarts="() => (isUploadingIntroVideo = true)"
          :onUploadEnds="() => (isUploadingIntroVideo = false)"
        />
      </div>
      <div class="column">
        <InputFile
          title="Déroulé de l'exercice *"
          type="video"
          :preview="editableExercise.videoUrl"
          v-model="editableExercise.videoUrl"
          @changeUrl="changeUrl"
          :typeFile="'video'"
          :onUploadStarts="() => (isUploadingVideo = true)"
          :onUploadEnds="() => (isUploadingVideo = false)"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <InputFile
          title="Conseil n°1"
          type="audio"
          :preview="editableExercise.advice1Url"
          v-model="editableExercise.advice1Url"
          @changeUrl="changeUrl"
          :typeFile="'advice1'"
          :onUploadStarts="() => (isUploadingAdvice1 = true)"
          :onUploadEnds="() => (isUploadingAdvice1 = false)"
        />
        <InputFile
          title="Conseil n°2"
          type="audio"
          :preview="editableExercise.advice2Url"
          v-model="editableExercise.advice2Url"
          @changeUrl="changeUrl"
          :typeFile="'advice2'"
          :onUploadStarts="() => (isUploadingAdvice2 = true)"
          :onUploadEnds="() => (isUploadingAdvice2 = false)"
        />
        <InputFile
          title="Conseil n°3"
          type="audio"
          :typeFile="'advice3'"
          @changeUrl="changeUrl"
          :preview="editableExercise.advice3Url"
          v-model="editableExercise.advice3Url"
          :onUploadStarts="() => (isUploadingAdvice3 = true)"
          :onUploadEnds="() => (isUploadingAdvice3 = false)"
        />
      </div>
    </div>

    <div class="row">
      <div class="column">
        <Select
          title="Thème de l'exercice"
          name="exercice-type"
          :options="exerciseTypes"
          v-model="editableExercise.type"
        />
        <Select
          title="Intensité de l'exercice"
          name="exercice-intensity"
          :options="difficultyLevels"
          v-model="editableExercise.intensity"
        />
        <Select
          title="Partie du corps"
          name="exercice-bodypart"
          :options="bodyParts"
          v-model="editableExercise.bodyPart"
        />
        <Select
          title="Position"
          name="exercice-position"
          :options="position"
          v-model="editableExercise.position"
        />
        <MultipleSelect
          title="Matériel"
          name="exercice-equipments"
          v-model="editableExercise.equipments"
          :options="equipments"
          :value="[...editableExercise.equipments]"
        />
      </div>
      <div class="column">
        <SwitchButton
          title="À faire accompagné d'un rééducateur"
          v-model="editableExercise.needReeducator"
        />
        <SwitchButton
          title="Exercice avec mouvement de RC"
          v-model="editableExercise.hasRC"
        />
        <Range
          title="Durée d'une série par défaut"
          name="exercice-defaultDuration"
          unit="s"
          :min="30"
          :max="600"
          :step="15"
          :initialValue="editableExercise.defaultDuration"
          v-model="editableExercise.defaultDuration"
        />
        <Range
          title="Nombre de séries par défaut"
          name="exercice-defaultCount"
          :min="1"
          :max="10"
          :initialValue="editableExercise.defaultCount"
          :step="1"
          v-model="editableExercise.defaultCount"
        />
        <Range
          title="Temps de récupération entre séries par défaut"
          name="exercice-defaultRestTime"
          unit="s"
          :min="10"
          :max="300"
          :initialValue="editableExercise.defaultRestTime"
          :step="10"
          v-model="editableExercise.defaultRestTime"
        />
      </div>
    </div>

    <BottomBar v-if="mode === 'edit'">
      <div class="message">
        <p v-if="hasExerciseChanged">Modifications non sauvegardées</p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        @click="submit"
        :disabled="isReadyToSave"
      />
      <Button title="Supprimer" @click="openModalDelete = true" type="warning" />
      <ConfirmationModal v-if="openModalDelete" @close="closeModal" @confirm="remove" />
    </BottomBar>

    <BottomBar v-if="mode === 'add'">
      <div class="message">
        <p v-if="hasExerciseChanged">Modifications non sauvegardées</p>
      </div>

      <Button
        title="Sauvegarder"
        type="primary"
        @click="submit"
        :disabled="isReadyToSave"
      />
    </BottomBar>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import BottomBar from "@/components/Layout/BottomBar"
import Input from "@/components/Forms/Fields/Input"
import InputFile from "@/components/Forms/Fields/InputFile"
import Textarea from "@/components/Forms/Fields/Textarea"
import SwitchButton from "@/components/Forms/Fields/SwitchButton"
import Select from "@/components/Forms/Fields/Select"
import MultipleSelect from "@/components/Forms/Fields/MultipleSelect"
import Range from "@/components/Forms/Fields/Range"
import Button from "@/components/Button"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "ExerciseForm",
  components: {
    Input,
    InputFile,
    Textarea,
    SwitchButton,
    Select,
    MultipleSelect,
    Range,
    Button,
    BottomBar,
    ConfirmationModal
  },
  props: {
    exercise: {
      default() {
        return {}
      }
    },
    mode: {
      type: String,
      default: "edit",
      validator: function (value) {
        return ["edit", "add"].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      originalExercise: {},
      editableExercise: {},
      isUploadingThumbnail: false,
      isUploadingIntroVideo: false,
      isUploadingVideo: false,
      isUploadingTitle: false,
      isUploadingAdvice1: false,
      isUploadingAdvice2: false,
      isUploadingAdvice3: false,
      isSubmitting: false,
      enableSave: true,
      openModalDelete: false
    }
  },
  async created() {
    if (this.mode === "add") {
      if (!this.editableExercise.defaultDuration) {
        this.editableExercise.defaultDuration = 30
      }
      if (!this.editableExercise.defaultCount) {
        this.editableExercise.defaultCount = 1
      }
      if (!this.editableExercise.defaultRestTime) {
        this.editableExercise.defaultRestTime = 10
      }
      this.editableExercise.video = {
        url: "",
        id: ""
      }
      this.editableExercise.introVideo = {
        url: "",
        id: ""
      }
      this.editableExercise.thumbnail = {
        url: "",
        id: ""
      }
      this.editableExercise.advice1 = {
        url: "",
        id: ""
      }
      this.editableExercise.advice2 = {
        url: "",
        id: ""
      }
      this.editableExercise.advice3 = {
        url: "",
        id: ""
      }
    } else {
      this.editableExercise = _.cloneDeep(this.exercise)
    }
    await this.$store.dispatch("admin/getEquipments")
    this.originalExercise = _.cloneDeep(this.exercise)
    if (this.editableExercise.equipments?.length) {
      this.editableExercise.equipments = this.editableExercise.equipments.map(
        (el) => el.id
      )
    }
  },
  methods: {
    changeUrl(data) {
      this.editableExercise[data.type + "Url"] = data.url
      this.editableExercise[data.type + "Id"] = data.id
      this.editableExercise[data.type] = {
        ...this.editableExercise[data.type],
        url: data.url,
        id: data.id
      }
    },
    closeModal() {
      this.openModalDelete = false
    },
    async remove() {
      await this.$store.dispatch("admin/deleteExercise", this.editableExercise.id)
      this.$router.push({ name: "ExercisesList" })
    },
    formatOptions(options) {
      return Object.entries(options).map((entry) => ({
        value: entry[0],
        name: entry[1]
      }))
    },
    async submit() {
      if (this.mode === "add") {
        this.isSubmitting = true
        this.editableExercise.videoId = this.editableExercise.video.id
        this.editableExercise.thumbnailId = this.editableExercise.thumbnail.id
        this.editableExercise.introVideoId = this.editableExercise.introVideo.id
        try {
          await this.$store.dispatch("admin/addExercise", {
            exercise: this.editableExercise
          })
          this.$router.push({ name: "ExercisesList" })
        } catch (e) {
          console.error(e)
        }

        this.isSubmitting = false
      } else if (this.mode === "edit") {
        this.editableExercise.equipments = this.editableExercise.equipments.map((el) => {
          return { id: el }
        })
        try {
          await this.$store.dispatch("admin/editExercise", {
            exercise: this.editableExercise
          })
          this.$router.push({ name: "ExercisesList" })
        } catch (e) {
          console.error(e)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      equipmentsList: "admin/equipments"
    }),
    exerciseTypes() {
      return this.formatOptions(this.$t("exerciseType"))
    },
    difficultyLevels() {
      return this.formatOptions(this.$t("exerciseIntensity"))
    },
    bodyParts() {
      return this.formatOptions(this.$t("bodyParts"))
    },
    position() {
      return this.formatOptions(this.$t("position"))
    },
    isReadyToSave() {
      return (
        !this.editableExercise.thumbnailUrl ||
        !this.editableExercise.name ||
        !this.editableExercise.videoUrl ||
        !this.editableExercise.introVideoUrl
      )
    },
    equipments() {
      return this.equipmentsList.map(({ id, name }) => ({ value: id, name }))
    },
    isUploading() {
      return (
        this.isUploadingThumbnail ||
        this.isUploadingIntroVideo ||
        this.isUploadingVideo ||
        this.isUploadingTitle ||
        this.isUploadingAdvice1 ||
        this.isUploadingAdvice2 ||
        this.isUploadingAdvice3 ||
        this.isSubmitting
      )
    },
    hasExerciseChanged() {
      return !_.isEqual(this.originalExercise, this.editableExercise)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  padding: 10px 0;
  margin-top: 20px;

  .row {
    div {
      flex: 1;
    }
  }

  .buttons-container {
    border-top: 1px solid $light-grey-color;
    justify-content: center;
    margin: 20px 0;
    padding-top: 20px;

    div {
      flex: 0;
    }
  }

  .message {
    flex-grow: 1;
  }
}
</style>
